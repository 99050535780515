import {
  Avatar,
  Listbox,
  ListboxItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button,
} from "@nextui-org/react";
import { Bell } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import * as m from "$paraglide/messages";
import { fullName } from "~/utils/user";
import { logoify } from "~/utils/string";
import { formatDistanceToNow } from "date-fns";
import { useEventSource } from "remix-utils/sse/react";
import { ActivitiesContext, ActivitiesContextType, Activity } from "~/contexts";
import { UserSession } from "~/utils/user-session";
import { useLocation } from "@remix-run/react";
import { SerializeFrom } from "@remix-run/node";
import { useFetcher } from "@remix-run/react";

const activityLink = (activity: Activity, forClient: boolean) => {
  const projectId = activity?.project?.id;
  const section = forClient ? "client" : "app";
  if (activity.type === "new_message") {
    return activity.product
      ? `/${section}/projects/${projectId}/products/${activity.product.id}/chat`
      : `/${section}/projects/${projectId}/chat`;
  }
  if (activity.type === "invitation" && activity.invitation) {
    return `/invitations/${activity.invitation.id}`;
  }
  if (activity.type === "product_state_update") {
    if (forClient) {
      return `/client/projects/${projectId}/products/${activity?.product?.id}`
    } else {
      return `/app/projects/${projectId}/products/${activity?.product?.id}/edit`
    }
  }
};

const activityTitle = (activity: Activity) => {
  if (activity.type === "new_message") {
    return m.activitiesNewMessageTitle({
      userName: fullName(activity.user, true),
    });
  }
  if (activity.type === "invitation" && activity.invitation) {
    return activity.invitation?.company
      ? m.wild_arable_stork_advise()
      : m.gaudy_heavy_firefox_ascend();
  }
  if (activity.type === "product_state_update") {
    return m.long_lazy_puffin_heal();
  }
};

const activitySubtitle = (activity: Activity) => {
  if (activity.type === "new_message") {
    return activity?.product?.title || activity.project?.title;
  }
  if (activity.type === "invitation") {
    return (
      activity.invitation?.project?.title || activity.invitation?.company?.name
    );
  }
};

const activityContent = (activity: Activity) => {
  if (activity.type === "new_message") {
    return (<div className="mt-2 whitespace-pre-line line-clamp-2 border-l-4 border-gray-200 pl-2">
      {(activity.meta as Record<string, string>).message}
    </div>
    )
  }
  if (activity.type === "product_state_update") {
    return (
      <div className="mt-1">
        {m.weird_alert_bumblebee_offer({
          user: fullName(activity.user, true),
          product: activity.product?.title ?? '',
          state: activity.product?.state?.name ?? '',
        })}
      </div>
    );
  }
};

export function ActivitiesPopover({
  user,
  forClient = false
}: {
  user: SerializeFrom<UserSession>;
  forClient?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { setActivities, activities } =
    useContext<ActivitiesContextType>(ActivitiesContext);
  const clearActivitiesFetcher = useFetcher();

  const location = useLocation();

  const ActivityComponent = ({ activity }: { activity: Activity }) => {
    return (
      <>
        <div className="flex gap-2 w-80 whitespace-normal">
          <div>
            <Avatar
              as="button"
              color="secondary"
              name={logoify(fullName(activity.user))}
              size="md"
              src={undefined}
              className="min-w-[32px]"
            />
          </div>
          <div className="grow">
            <div className="font-bold">{activityTitle(activity)}</div>
            <div className="text-xs text-gray-500">
              {activitySubtitle(activity)}
            </div>
            {activityContent(activity)}
            <div className="text-xs text-gray-400 mt-2 text-right">
              {formatDistanceToNow(activity.createdAt)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const newActivity = useEventSource("/activities/subscribe", {
    event: "activities/new",
  });

  const canInsertActivity = (activity: Activity) => {
    if (activity.user.id === user.id) return false;

    if (activity.type === "new_message") {
      if (activity.product) {
        if (
          location.pathname.includes(`/products/${activity.product.id}/chat`)
        ) {
          return false;
        }
      } else if (activity.project) {
        if (
          location.pathname.includes(`/projects/${activity.project.id}/chat`)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!newActivity) return;

    const activity = JSON.parse(newActivity);

    if (!canInsertActivity(activity)) return;

    setActivities((prevActivities: Activity[]) => {
      return [activity, ...prevActivities];
    });
  }, [newActivity]);

  const handleClearActivities = () => {
    clearActivitiesFetcher.submit(
      {},
      { method: "POST", action: "/activities/clear" }
    );
    setActivities([]);
    setIsOpen(false);
  };

  return (
    <>
      <Popover
        placement="bottom"
        isOpen={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
      >
        <PopoverTrigger>
          <button type="button" className="outline-0 mr-3 relative">
            <Bell strokeWidth={1.5} />
            {activities.length > 0 && (
              <div className="absolute right-0 top-0">
                <span className="relative flex h-3 w-3">
                  <span className="[animation-iteration-count:3] animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
                </span>
              </div>
            )}
          </button>
        </PopoverTrigger>
        <PopoverContent>
          {activities.length > 0 ? (
            <>
              <Listbox className="max-h-96 overflow-y-auto">
                {activities.map((activity) => (
                  <ListboxItem
                    key={activity.id}
                    href={activityLink(activity, forClient)}
                    onClick={() => setIsOpen(false)}
                    aria-label={activity.type}
                  >
                    <ActivityComponent key={activity.id} activity={activity} />
                  </ListboxItem>
                ))}
              </Listbox>
              <div className="mt-2 grow w-full">
                <Button
                  size="sm"
                  color="primary"
                  variant="light"
                  onClick={handleClearActivities}
                  isLoading={clearActivitiesFetcher.state === "submitting"}
                  fullWidth
                >
                  {m.sleek_minor_leopard_honor()}
                </Button>
              </div>
            </>
          ) : (
            <div className="p-4 text-center max-w-80">
              <strong>{m.activitiesNoActivitiesTitle()}</strong>
              <div className="text-gray-500">
                {m.activitiesNoActivitiesDescription()}
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
}
