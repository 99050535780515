import { capitalizeFirstLetter } from "./string";

type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  locale: string;
};

export function fullName(user: UserType, truncateLastName = false) {
  const lastName = truncateLastName
    ? `${capitalizeFirstLetter(user.lastName, true)}.`
    : user.lastName;

  return `${user.firstName} ${lastName}`;
}